:root {
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 2rem;
  --spacing-lg: 4rem;

  --glow-spacing-x: var(--spacing-md);
  --glow-spacing-y: var(--spacing-lg);
}

/* TODO: refactor to use semantic scale */
:root {
  --spacing-1: 0.25rem; /* 4px */
  --spacing-2: 0.5rem; /* 8px */
  --spacing-3: 0.75rem; /* 12px */
  --spacing-4: 1rem; /* 16px */
  --spacing-5: 1.25rem; /* 20px */
  --spacing-6: 1.5rem; /* 24px */
  --spacing-8: 2rem; /* 32px */
  --spacing-10: 2.5rem; /* 40px */
  --spacing-12: 3rem; /* 48px */
  --spacing-16: 4rem; /* 64px */
  --spacing-20: 5rem; /* 80px */
  --spacing-24: 6rem; /* 96px */
  --spacing-32: 8rem; /* 128px */
  --spacing-40: 10rem; /* 160px */
  --spacing-48: 12rem; /* 192px */
  --spacing-56: 14rem; /* 224px */
  --spacing-64: 16rem; /* 256px */
}

.box {
  box-sizing: border-box;
}

.box h1,
.box h2,
.box h3,
.box h4 {
  margin: 0;
}

@each $size in none, xxs, xs, sm, md, lg {
  .p-$(size) {
    padding: var(--spacing-$(size));
  }

  .px-$(size) {
    padding-left: var(--spacing-$(size));
    padding-right: var(--spacing-$(size));
  }

  .py-$(size) {
    padding-top: var(--spacing-$(size));
    padding-bottom: var(--spacing-$(size));
  }

  .pl-$(size) {
    padding-left: var(--spacing-$(size));
  }

  .pr-$(size) {
    padding-right: var(--spacing-$(size));
  }

  .pt-$(size) {
    padding-top: var(--spacing-$(size));
  }

  .pb-$(size) {
    padding-bottom: var(--spacing-$(size));
  }
}

@each $size in none, xxs, xs, sm, md, lg {
  .m-$(size) {
    margin: var(--spacing-$(size));
  }

  .mx-$(size) {
    margin-left: var(--spacing-$(size));
    margin-right: var(--spacing-$(size));
  }

  .my-$(size) {
    margin-top: var(--spacing-$(size));
    margin-bottom: var(--spacing-$(size));
  }

  .ml-$(size) {
    margin-left: var(--spacing-$(size));
  }

  .mr-$(size) {
    margin-right: var(--spacing-$(size));
  }

  .mt-$(size) {
    margin-top: var(--spacing-$(size));
  }

  .mb-$(size) {
    margin-bottom: var(--spacing-$(size));
  }
}
