:root {
  --subtle-avatar-shadow: inset 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
}

/** Defines an avatar */
.avatar {
  box-sizing: border-box;
  border-radius: var(--rounded-full);
  border-style: solid;
  border-width: 1px;

  text-transform: uppercase;

  height: 4.4rem;
  width: 4.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.avatar-lg-stroke {
  border-width: 3px;
}

.avatar-no-border {
  border-width: 0;
  box-shadow: var(--subtle-avatar-shadow);
}

.avatar-subtle {
  filter: grayscale(100%);
  box-shadow: var(--subtle-avatar-shadow);
}

.avatar .text {
  text-transform: uppercase;

  /* Fira code is adding 1px to the bottom of the span */
  transform: translateY(1px);
}
