.select {
  border-color: var(--accentColor);
  border-radius: var(--rounded-lg);
  background: var(--blackSecondary);
  border: 0.3rem solid transparent;
  box-sizing: border-box;
  color: var(--white);
  transition: 0.3s ease-in-out all;
  appearance: none;
  outline: none;
  position: relative;
  width: 100%;
}

.select::before {
  border-style: solid;
  border-width: 0.25rem 0.25rem 0 0;
  content: '';
  display: inline-block;
  height: 0.45rem;
  left: 0.15rem;
  position: relative;
  top: 0.15rem;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45rem;
  left: 0;
  transform: rotate(45deg);
  background-color: white;
}

.select:focus {
  border-color: var(--white);
}

.select::placeholder {
  color: var(--blackTernary);
}

.select:disabled {
  background: var(--blackTernary);
  cursor: not-allowed;
  opacity: 0.9;
}

.select-outline,
.select-outline:disabled {
  background: transparent;
  border-color: var(--blackTernary);
}

.select-arrow {
  position: absolute;
  display: inline-flex;
  width: var(--spacing-8);
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  right: var(--spacing-2);
  top: 50%;
  pointer-events: none;
  z-index: 2;
  transform: translateY(-50%);
}

.select-arrow svg {
  width: 3.6rem;
  height: 3.6rem;
  fill: currentColor;
}

.select-outline.select-black,
.select-outline.select-black:focus,
.select.select-black:focus {
  border-color: var(--black);
}
.select-outline.select-white,
.select-outline.select-white:focus,
.select.select-white:focus {
  border-color: var(--white);
}
.select-outline.select-cyan,
.select-outline.select-cyan:focus,
.select.select-cyan:focus {
  border-color: var(--cyan);
}
.select-outline.select-green,
.select-outline.select-green:focus,
.select.select-green:focus {
  border-color: var(--green);
}
.select-outline.select-orange,
.select-outline.select-orange:focus,
.select.select-orange:focus {
  border-color: var(--orange);
}
.select-outline.select-pink,
.select-outline.select-pink:focus,
.select.select-pink:focus {
  border-color: var(--pink);
}
.select-outline.select-purple,
.select-outline.select-purple:focus,
.select.select-purple:focus {
  border-color: var(--purple);
}
.select-outline.select-red,
.select-outline.select-red:focus,
.select.select-red:focus {
  border-color: var(--red);
}
.select-outline.select-yellow,
.select-outline.select-yellow:focus,
.select.select-yellow:focus {
  border-color: var(--yellow);
}

/* Sizes */

.select.select-sm {
  height: var(--spacing-8);
  font-size: var(--font-sm);
}

.select {
  height: var(--spacing-10);
  font-size: var(--font-md);
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-8);
}

.select.select-lg {
  height: var(--spacing-12);
  font-size: var(--font-lg);
}
