.tabs {
  list-style: none;
  display: flex;
  padding: 0;
  border-color: var(--accentColor);
}

.tab {
  border-bottom-color: var(--disabled);
  border-bottom-width: 0.2rem;
  border-bottom-style: solid;
  padding: 1rem;
}

.tab-link {
  color: var(--white);
  text-decoration: none;
}

@each $color in black, white, cyan, green, orange, pink, purple, red, yellow {
  .tabs-$(color) .tab-active {
    border-color: var(--$(color));
  }

  .tabs-$(color) .tab-active .tab-link {
    color: var(--$(color));
  }
}
